
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import { useEffect, useState } from "react";
import { Provider } from "react-redux";
import { ChakraProvider } from "@chakra-ui/react";
import { getCookie } from "cookies-next";
import { Session } from "next-auth";
import { SessionProvider } from "next-auth/react";
import type { AppProps } from "next/app";
import { useRouter } from "next/router";
import Script from "next/script";
import { GoogleAnalytics } from "nextjs-google-analytics";
import Favicon from "@components/Favicon";
import ConfigProvider from "@components/layout/ConfigProvider";
import Layout from "@components/layout/Layout";
import { IPageData } from "@components/ResourceView/types";
import Meta from "@components/seo/Meta";
import { AppStaticPathContext } from "@context/AppStaticPathContext";
import CombinedContext from "@context/CombinedContext";
import { CountryListContext } from "@context/CountryListContext";
import { LoadingProvider } from "@context/LoadingProvider";
import { UserProvider } from "@context/UserCookiesContext";
import { TGlobalData } from "@middlewares/globalData";
import { APP_ROUTES } from "@utils/app-routes";
import { DISTRIBUTOR_SELECTOR_PAGE_SLUG } from "@utils/constants";
import { getWebViewPageLayout, TPageLayout } from "@utils/layout-utils";
import store from "store/store";
import theme from "../theme";
import "@styles/globals.css";
import "@styles/apple-pay.css";
import "@styles/lazy-youtube-player.css";
import "@styles/review.css";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "@fontsource/barlow/300.css";
import "@fontsource/barlow/400.css";
import "@fontsource/barlow/500.css";
import "@fontsource/barlow/700.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
const isWebView = getCookie("Is-Web-View");
if (isWebView) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    import("@styles/dark-mode.css");
}
type TMyAppProps = TGlobalData & {
    session: Session;
    pageData: IPageData;
};
const BaseMeta = ({ config }) => {
    if (!config) {
        return null;
    }
    const { google_tag_manager_key, affiliatly_key, google_analytics_tag_id } = config;
    return (<>
      <Meta />
      {google_tag_manager_key && (<Script id="google-tag-manager" strategy="afterInteractive">
          {/* eslint-disable-next-line max-len */}
          {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${google_tag_manager_key}');`}
        </Script>)}
      {affiliatly_key && (<Script strategy="afterInteractive" src={`https://static.affiliatly.com/v3/affiliatly.js?affiliatly_code=${affiliatly_key}`}/>)}

      {google_analytics_tag_id && <GoogleAnalytics trackPageViews gaMeasurementId={google_analytics_tag_id}/>}
      {google_tag_manager_key && (<noscript 
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
                // eslint-disable-next-line max-len
                __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=${google_tag_manager_key}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
            }}/>)}
    </>);
};
const CLEAN_LAYOUT_PAGES = [`${APP_ROUTES.signOut}`];
const IFRAME_PAGE = DISTRIBUTOR_SELECTOR_PAGE_SLUG;
const MyApp = ({ Component, pageProps: { session, ...pageProps } }: AppProps<TMyAppProps>): JSX.Element => {
    const router = useRouter();
    const defaultLayout: TPageLayout = {
        hideHeader: pageProps?.page?.hideHeader || false,
        hideFooter: pageProps?.page?.hideFooter || false,
        stickyPayment: false,
    };
    const [layoutConfig, setLayoutConfig] = useState(defaultLayout);
    useEffect(() => {
        if (isWebView) {
            setLayoutConfig(getWebViewPageLayout());
        }
    }, []);
    /**
     * Ignore all layout components for iframe pages
     */
    if (router.asPath.indexOf(IFRAME_PAGE) > -1) {
        return (<Provider store={store}>
        <ChakraProvider theme={theme}>
          <Component {...pageProps}/>
        </ChakraProvider>
      </Provider>);
    }
    /**
     * Ignore all layout components for clean pages
     */
    if (CLEAN_LAYOUT_PAGES.indexOf(router.pathname) > -1) {
        return (<ChakraProvider theme={theme}>
        <Component {...pageProps}/>
      </ChakraProvider>);
    }
    return (<>
      <BaseMeta config={pageProps.config}/>
      <CombinedContext components={[UserProvider]}>
        <SessionProvider session={session}>
          <Provider store={store}>
            <AppStaticPathContext initialState={pageProps.appStaticPath || {}}>
              <CountryListContext countries={pageProps.countries}>
                <ConfigProvider config={pageProps.config}/>
                <ChakraProvider theme={theme}>
                  <LoadingProvider>
                    <Favicon />
                    <Layout footerNavLinks={pageProps.footerNavLinks} headerNavLinks={pageProps.headerNavLinks} socialLinks={pageProps.socialLinks} appProviders={pageProps.appProviders} paymentProviders={pageProps.paymentProviders} languages={pageProps.languages} userNavLinks={pageProps.userNavLinks} affiliatesBlock={pageProps.affiliatesBlock} appStaticPath={pageProps.appStaticPath} config={pageProps.config} pageType={pageProps?.page?.resourceCategory} hideHeader={layoutConfig.hideHeader} hideFooter={layoutConfig.hideFooter}>
                      <Component {...pageProps} hideHeader={layoutConfig.hideHeader} hideFooter={layoutConfig.hideFooter} stickyPayment={layoutConfig.stickyPayment}/>
                    </Layout>
                  </LoadingProvider>
                </ChakraProvider>
              </CountryListContext>
            </AppStaticPathContext>
          </Provider>
        </SessionProvider>
      </CombinedContext>
    </>);
};
const __Next_Translate__Page__194877131ce__ = MyApp;

    export default __appWithI18n(__Next_Translate__Page__194877131ce__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      
    });
  